import React from "react";

import SEO from "components/seo/index";

import Paypalbanner from "components/paypal/banner";

import Initiativecontent from "components/paypal/inititativecontent";

// import Uniqueindustry from "components/paypal/unique-industry";

import Programexecute from "components/paypal/program-execute";

import Paypallayout from "../paypallayout/index";

// import SimpleReactLightbox from "simple-react-lightbox";

// import RecentPlantations from "components/bosch/recentplantation";

import Counterbox from "components/paypal/counterbox";

import Wherewhen from "components/paypal/where-when-map";

import Plantationevents from "components/paypal/plantation-events";

const Paypalplantation = () => {
  return (
    <>
      <SEO title="Paypal Forest Plantation Drive" />
      <Paypallayout>
        <section id="paypal-banner">
          <Paypalbanner />
        </section>
        <section id="initiative-bg">
          <Initiativecontent />
        </section>
        <section>
          <Wherewhen />
        </section>
        <section id="where-when">
          <Plantationevents />
        </section>
        {/* <section id="projectsunique">
          <Uniqueindustry />
        </section> */}
        <section id="project-execute">
          <Programexecute />
        </section>

        <section id="counterbox">
          <Counterbox />
        </section>
      </Paypallayout>
    </>
  );
};

export default Paypalplantation;
