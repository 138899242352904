import React from "react";

import chikka from "assets/images/paypal/plantation-events/chikka-pic.png";

import kanhaevents from "assets/images/paypal/plantation-events/kanha-events.png";

import { Link } from "gatsby";

import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";

import Ksvteleganavideo from "components/paypal/ksvteleganavideo";

import Kanhavideo from "components/paypal/kanha-popup";

export default function Plantationevents() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 2,
      // partialVisibilityGutter: 30,
    },
  };

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div className="carousel-button-group">
        {/* <div className='arrow-navigations custom-arrow-navigation-event'>
          <button className='pr-custom zcaler-top-slide-button' onClick={() => previous()}>
            <i className='icon icon-arrow-left-short'></i>
          </button>
          <button className='pr-rightcustom zcaler-top-slide-button zscalar-right-custom' onClick={() => next()}>
            <i className='icon icon-arrow-right-short'></i>
          </button>
        </div> */}
      </div>
    );
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h3 className="title-theme-heading hccb-heading">
            {" "}
            Plantation Events{" "}
          </h3>
        </div>

        <div className="carousel-section">
          <Carousel
            arrows={true}
            autoPlaySpeed={3000}
            draggable
            infinite
            keyBoardControl
            minimumTouchDrag={80}
            partialVisible
            renderButtonGroupOutside={true}
            swipeable
            responsive={responsive}
            customButtonGroup={<ButtonGroup />}
          >
            <div className="card where-card bgsw-card d-flex align-items-stretch">
              <img src={chikka} className="img-fluid" alt="Shanthivanam" />
              <div className="card-body where-body">
                <div>
                  <p className="card-text">
                    <span>
                      {" "}
                      Chikka Timmasandra Lake, 17th & 18th February 2023,
                      Bangalore{" "}
                    </span>{" "}
                  </p>
                  <p className="card-text">
                    Padmashri Dr. Saalumarada Thimmaka inaugurated the lake on
                    17th February, 2023 <span> </span>
                  </p>

                  <p className="card-text">
                    Tree plantation by 100+ PayPal employees on 18th February,
                    largely to prevent soil erosion of the lake bund.{" "}
                    <span> </span>
                  </p>
                  <p className="card-text">
                    Cultural activities by local villagers on 18th February 2023
                  </p>
                  <div>
                    <Ksvteleganavideo />
                    <Link
                      className="viewmap-tag"
                      to="https://drive.google.com/drive/u/1/folders/1m3YpMYSUR6DBGVjaFhvj36SQh2Z3ELxp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      View in Images
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="card where-card bgsw-card d-flex align-items-stretch">
              <img
                src={kanhaevents}
                className="img-fluid"
                alt="Employee Engagement Event "
              />
              <div className="card-body where-body">
                <p className="card-text">
                  <span> Employee Engagement Event for Paypal, Hyderabad </span>
                </p>
                <p className="card-text">
                  <span>
                    {" "}
                    Kanha Shanti Vanam, 18th February 2023, Hyderabad{" "}
                  </span>
                </p>
                <p className="card-text">
                  Around 70 PayPal employees with families participated in
                  plantation activity.
                </p>

                <p className="card-text">
                  <span> 620 </span> native & endangered trees planted across 3
                  locations to mark the event
                </p>

                <div>
                  <Kanhavideo />
                  <Link
                    className="viewmap-tag"
                    to="https://drive.google.com/drive/u/1/folders/1uKQkjIAJVrAp3M_FvX-BSemHm_ebuBtP"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View in Images
                  </Link>
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
}
