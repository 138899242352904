import React from "react";

import { paypalinitaiaivecontentdata } from "assets/data/paypal/paypalinitativecontent";

export default function Initiativecontent() {
  return (
    <div className="objectiveforest paypal_inner-pages pb-0 initiative-bg">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="paypal-heading"> Why this initiative? </h3>
            <p className="paypal-p-text">
              {" "}
              A mere sapling planted by each of us is already a significant
              amount of carbon emission mitigated, rainfall captured, oxygen
              generated, and wildlife sustained. we want
              to create a carbon sink and contribute towards a net-zero future.
              In addition to creating bio-diversity spots across the country,
              some of the saplings will also aid the income of people in the
              local communities. The initiative will help us offset 1500 tonnes
              of CO2 emissions over the next 3-5 years.
            </p>
          </div>
          <div className="row object-section-row">
            {paypalinitaiaivecontentdata.map((item) => {
              return (
                <div className="col-md-3 col-sm-3" key={item.id}>
                  <div className="object-box-wrapper">
                    <div className="object-img">
                      <img
                        data-src={item.img}
                        className="lazyload"
                        alt={item.alt}
                      />
                    </div>
                    <div className="object-content initiative-box">
                      <p dangerouslySetInnerHTML={{ __html: item.title }}></p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
