import treesplanned from "assets/images/paypal/initiative/trees-plantation.png";
import engagementspecies from "assets/images/paypal/initiative/engagement-species.png";
import trans from "assets/images/paypal/initiative/trans.png";
import acres from "assets/images/paypal/initiative/acres.png";

export const paypalinitaiaivecontentdata = [
  {
    id: 1,
    img: treesplanned,
    alt: "treesplanned",
    title: " <span> 60,000 </span> Trees Planted",
  },

  {
    id: 2,
    img: engagementspecies,
    alt: "nature",
    title: "<span> 6 red listed species planted</span>",
  },
  {
    id: 3,
    img: trans,
    alt: "plannedtranslocated",
    title: "<span> 15 trees translocated </span>",
  },
  {
    id: 4,
    img: acres,
    alt: "Acres",
    title:
      "<span>158 + Acres </span> of Biodiversity imapact through 6 plantation sites & 2 lake restoration",
  },
];
