import React from "react";
import paypalbannertree from "assets/images/paypal/banner/greener.png";

export default function Paypalbanner() {
  return (
    <div className="paypal-banner pb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <h3 className="paypal-heading">
              {" "}
              Pledging towards a greener future{" "}
              {/* <span className="dis-block">
                {" "}
                <span className="red-title-clr"> 3 million trees </span> by
                2025?{" "}
              </span>{" "} */}
            </h3>

            <p className="paypal-p-text">
              {" "}
              As we race against time, every passing year is witnessing sharper
              decline in natural resources, increased loss of biodiversity,
              degrading soil quality and many other manifestations of climate
              crisis. There is a greater need than ever before for leading
              organisations like ours to make “nature positive” eco conscious
              decisions and actively contribute towards environmental
              initiatives for a greener future. PayPal is committed to
              responsible innovation and management of key environmental, social
              and governance (ESG) opportunities and risks. This initiative, in
              partnership with Heartfulness Institute, reflects PayPal’s
              increasing commitment towards environmental causes in India,
              especially in locations where PayPal operates. Through this
              collaboration PayPal aims to plant more than{" "}
              <span className="red-title-clr"> 60,000 </span> saplings
              <span className="red-title-clr"> across Indian states </span> and
              rejuvenate two degraded lakes in the suburban regions of Bangalore
              & Hyderabad, with a long term target of more than{" "}
              <span className="red-title-clr"> 1 million </span> trees across
              different states
            </p>
          </div>
          <div className="col-lg-6 col-md-6">
            <img
              style={{ marginTop: "20px" }}
              className="img-fluid tree-logo-custom"
              src={paypalbannertree}
              alt="bannar tree"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
