import React from "react";

import facebook from "assets/images/hccb/fb.png";
import twitter from "assets/images/hccb/twitter-pic.png";
import instagram from "assets/images/hccb/insta-pic.png";
import youtube from "assets/images/hccb/youtube-pic.png";
import linkedin from "assets/images/hccb/linkedin-pic.png";

import locationpic from "assets/images/hccb/location-pic.png";

import phonepic from "assets/images/hccb/phone-pic.png";

import mailenvelope from "assets/images/hccb/mail-envelope.png";

import footerlogo from "assets/images/hccb/hccb-logo.png";

import paypallogo from "assets/images/paypal/paypal-logo.png";

const Footer = () => {
  return (
    <>
      <div className="footer-wrapper">
        <div
          className="container msg-info-wrapper hccb-info-wrapper"
          id="contact"
        >
          <div className="row"></div>
        </div>
        <footer className="footer-section1 hccb-footer-section">
          <div className="container">
            <div className="row footer-widget-row position-set">
              <div className="col-md-4 logo-section footer-widget set-foot-padd">
                <div className="">
                  <span>
                    <img
                      className="foot-bosch-logo foot-logo-hccb"
                      src={paypallogo}
                      alt="HFN Logo"
                    />
                  </span>
                </div>

                <p className="foot-p-content"></p>
              </div>
              <div className="col-md-2"></div>

              <div className="col-md-6 contact footer-widget bosch-foot-widget">
                <h3 className="paypal-heading mg-bottom30">Contact</h3>
                <ul className="address-items">
                  <li>
                    <a>
                      {" "}
                      <span>
                        {" "}
                        <img
                          className="img-fluid"
                          src={locationpic}
                          alt="Location Pic"
                        />{" "}
                      </span>{" "}
                      Kanha Village, Nandigama Mandal Telangana - 509325
                    </a>
                  </li>
                  <li>
                    <a>
                      {" "}
                      <span>
                        {" "}
                        <img
                          className="img-fluid"
                          src={phonepic}
                          alt="Phone Pic"
                        />{" "}
                      </span>{" "}
                      Phone: 9876543210{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:fbh@heartfulness.org"
                      style={{ color: "#000", textDecoration: "none" }}
                    >
                      <span>
                        {" "}
                        <img
                          className="img-fluid"
                          src={mailenvelope}
                          alt="Location Pic"
                        />{" "}
                      </span>{" "}
                      fbh@heartfulness.org{" "}
                    </a>
                  </li>
                </ul>

                {/* <div className="col-md-6">
                  <ul className="hccb-social-icon">
                    <li>
                      <a
                        href="https://www.facebook.com/HCCBOfficial/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={facebook} alt="facebook" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/HCCB_Official?t=SC100mWRiobKcVEATI5OxA&s=09"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={twitter} alt="twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://youtube.com/c/HindustanCocaColaBeveragesPvtLtd"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={youtube} alt="youtube" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/hccb/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={linkedin} alt="linkedin" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://instagram.com/hccb_official?igshid=ZmVmZTY5ZGE="
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={instagram} alt="instagram" />
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </footer>
      </div>

      <div className="footer-bottom">
        <div className="container">
          <div className="row copy-rights-row pd-bottom20">
            <div className="col-md-12">
              <p className="copyright-text">
                Copyrights {new Date().getFullYear()}. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
