export const paypalplantation = {
  tabName: "Plantation",
  carousel: [
    {
      id: 1,
      state: "Telangana",
      saplings: "7500",
      area: "50 Acres",
      plantationsite: "Lake Restoration, Kanha Sarovar, Sai Reddy Guda,",
      plantationperiod: "November 2022 to March 2023",
      plantationstyle: "Lake Restoration",
      species: "Golden shower (White), Palash (Yellow), Black",
      // projectstatus: "Completed",
      keyobjectivies:
        "Prevent flooding of farm lands during rains owing to lake overflow 1.increse water holding Capacity of the lake 50% and recharge aquifiers 2. Create a Permanent waterbody to provide a sanctuary for local & migratory  birds in all seasons 3.to enhance the biodiversity of the region",
      map: "",
      plantationtitle: "Lake restoration Pictures",
      images: [
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/lake-restoration/image1.jpg",
          alt: "Lake Restoration, Kanha Sarovar",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/lake-restoration/image2.jpg",
          alt: "Lake Restoration, Kanha Sarovar",
        },

        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/lake-restoration/image3.jpg",
          alt: "Lake Restoration, Kanha Sarovar",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/lake-restoration/image4.jpg",
          alt: "Lake Restoration, Kanha Sarovar",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/lake-restoration/image5.jpg",
          alt: "Lake Restoration, Kanha Sarovar",
        },
      ],
    },

    {
      id: 2,
      state: "Karnataka",
      saplings: "",
      area: "12 Acres",
      plantationsite: "Lake Restoration,Chikka Timmasandra Lake,",
      plantationperiod: "November 2022 to March 2023",
      plantationstyle: "Lake Restoration",
      species: "",
      projectstatus: "Completed",
      keyobjectivies:
        "Restoration of lake and its ecosystem to serve the villages around the lake. Prevent flooding and recharge aquifers. Enhance biodiversity by planting native trees and shrubs",
      map: "",
      plantationtitle: "Lake restoration Pictures",
      images: [
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/chikka-timmasandra-lake/image1.jpeg",
          alt: "Chikka Timmasandra Lake",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/chikka-timmasandra-lake/image2.jpg",
          alt: "Chikka Timmasandra Lake",
        },

        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/chikka-timmasandra-lake/image3.jpg",
          alt: "Chikka Timmasandra Lake",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/chikka-timmasandra-lake/image4.jpg",
          alt: "Chikka Timmasandra Lake",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/chikka-timmasandra-lake/image5.jpeg",
          alt: "Chikka Timmasandra Lake",
        },
      ],
    },
    {
      id: 3,
      state: "Tamil Nadu",
      saplings: "25000",
      area: "28 Acres",
      plantationsite: "Village endowment program, Madurai District Villages,",
      plantationperiod: "November 2022 to March 2023",
      plantationstyle: "Economic Forest",
      species: "Mahogany, Sithapal, Arjuna",
      projectstatus: "Completed",
      keyobjectivies:
        "Food & Nutrition security for the villagers in the short term. Creation of a village endowment in the long term. Enhance biodiversity",
      map: "",
      plantationtitle: "Plantation site pictures",
      images: [
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/madurai/image1.jpg",
          alt: "Madurai District Villages,",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/madurai/image2.jpg",
          alt: "Madurai District Villages,",
        },

        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/madurai/image3.jpg",
          alt: "Madurai District Villages,",
        },

        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/madurai/image5.jpg",
          alt: "Madurai District Villages,",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/madurai/image6.jpg",
          alt: "Madurai District Villages,",
        },
      ],
    },
    {
      id: 4,
      state: "Telangana",
      saplings: "10000",
      area: "34.4 Acres",
      plantationsite: "Conservation Center , Kanha Shanthi vanam KSV Layout",
      plantationperiod: "November 2022 to March 2023",
      plantationstyle: "Dense Forest Plantation",
      species: "Saraca Asoca, Syzygium stocksii, Machilus glaucescens,",
      projectstatus: "Completed",
      keyobjectivies:
        "1. Conservation of native, endangered and red listed species of trees. \n 2. To attract local wild life.3. Increase biodiversity",
      map: "",
      plantationtitle: "Plantation site pictures",
      images: [
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/star-building-kanha/image1.jpg",
          alt: "Conservation Center , Kanha Shanthi vanam",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/star-building-kanha/image2.jpg",
          alt: "Conservation Center , Kanha Shanthi vanam",
        },

        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/star-building-kanha/image3.jpg",
          alt: "Conservation Center , Kanha Shanthi vanam",
        },

        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/star-building-kanha/image4.jpg",
          alt: "Conservation Center , Kanha Shanthi vanam",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/star-building-kanha/image5.jpg",
          alt: "Conservation Center , Kanha Shanthi vanam",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/star-building-kanha/image6.jpg",
          alt: "Conservation Center , Kanha Shanthi vanam",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/star-building-kanha/image7.jpg",
          alt: "Conservation Center , Kanha Shanthi vanam",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/star-building-kanha/image8.jpg",
          alt: "Conservation Center , Kanha Shanthi vanam",
        },
      ],
    },
    {
      id: 5,
      state: "Madhya Pradesh",
      saplings: "18000",
      area: "30 Acres",
      plantationsite: "Hill Restoration, Joara Police Academy",
      plantationperiod: "November 2022 to March 2023",
      plantationstyle: "Hill Restoration",
      species: "Arjuna, East Indian sandalwood, Red sanders",
      projectstatus: "Completed",
      keyobjectivies:
        "1. Hill ecosystem restoration leading to improvement of biodiversity in the region 2. Conserve and nurture native tree species of Madhya Pradesh 3. Provide a sanctuary for local wildlife",
      map: "",
      plantationtitle: "Plantation site pictures",
      images: [
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/joara/image1.jpg",
          alt: "Joara Police Academy",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/joara/image2.jpg",
          alt: "Joara Police Academy",
        },

        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/joara/image3.jpg",
          alt: "Joara Police Academy",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/joara/image4.jpeg",
          alt: "Joara Police Academy",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/joara/image5.jpeg",
          alt: "Joara Police Academy",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/joara/image6.jpeg",
          alt: "Joara Police Academy",
        },
      ],
    },
    {
      id: 6,
      state: "Telangana",
      saplings: "7000",
      area: "2.14 Acres",
      plantationsite: "Ecosystem Restoration, Chegur village KSV layout, ",
      plantationperiod: "Nov 2022- March 2023",
      plantationstyle: "Rain forest and endangered species plantation",
      species:
        "Ramphal, Mango, Sindoor, Red sanders, East Indian sandal wood, Kerala jamun.",
      projectstatus: "Completed",
      keyobjectivies:
        "1. Ecosystem restoration and creation of biodiversity hotspot.2. Conservation of Indian rain forest species",
      map: "",
      plantationtitle: "Plantation site pictures",
      images: [
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/phase-kanha/image1.jpg",
          alt: "Chegur village",
        },
        {
          id: 2,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/phase-kanha/image2.jpg",
          alt: "Chegur village",
        },
        {
          id: 3,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/phase-kanha/image3.jpeg",
          alt: "Chegur village",
        },
        {
          id: 4,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/phase-kanha/image4.jpeg",
          alt: "Chegur village",
        },
        {
          id: 5,
          img: "https://cdn-prod.heartfulness.org/FBH/Paypal/phase-kanha/image5.jpg",
          alt: "Chegur village",
        },
      ],
    },
    // {
    //   id: 7,
    //   state: "Karnataka",
    //   saplings: "630",
    //   area: "Acres",
    //   plantationsite: "Kanha Shanti Vanam, Hyderabad",
    //   plantationperiod: "Nov 2022- March 2023",
    //   plantationstyle: "Plantation Drive",
    //   species: " Santalum album (East Indian sandal wood), Pterocarpus",
    //   projectstatus: "Ongoing",
    //   keyobjectivies: "",
    //   map: "",
    //   images: [
    //     {
    //       id: 1,
    //       img: "https://cdn-prod.heartfulness.org/FBH/Paypal/phase-kanha/image1.jpg",
    //       alt: "Kanha Shanti Vanam",
    //     },
    //     {
    //       id: 2,
    //       img: "https://cdn-prod.heartfulness.org/FBH/Paypal/phase-kanha/image2.jpg",
    //       alt: "Kanha Shanti Vanam",
    //     },
    //     {
    //       id: 3,
    //       img: "https://cdn-prod.heartfulness.org/FBH/Paypal/phase-kanha/image3.jpeg",
    //       alt: "Kanha Shanti Vanam",
    //     },
    //     {
    //       id: 4,
    //       img: "https://cdn-prod.heartfulness.org/FBH/Paypal/phase-kanha/image4.jpeg",
    //       alt: "Kanha Shanti Vanam",
    //     },
    //     {
    //       id: 5,
    //       img: "https://cdn-prod.heartfulness.org/FBH/Paypal/phase-kanha/image5.jpg",
    //       alt: "Kanha Shanti Vanam",
    //     },
    //   ],
    // },
  ],
  location: [
    {
      id: 1,
      name: "Lake Restoration, Kanha Sarovar- Telangana",
      x: 38,
      y: 68,
    },
    {
      id: 2,
      name: "Lake Restoration,Chikka Timmasandra Lake, - Karnataka",
      x: 29,
      y: 79,
    },
    {
      id: 3,
      name: "Village endowment program - Madurai",
      x: 31,
      y: 92,
    },
    {
      id: 4,
      name: "Conservation Center , Kanha Shanthi vanam - Telangana",
      x: 33,
      y: 67,
    },
    {
      id: 5,
      name: "Jaora Police Academy - Madhya Pradesh",
      x: 22,
      y: 43,
    },
    {
      id: 6,
      name: "Ecosystem Restoration, Chegur village - Telangana",
      x: 31,
      y: 70,
    },
    // {
    //   id: 7,
    //   name: "Kanha Shanti Vanam,- Hyderabad",
    //   x: 34,
    //   y: 72,
    // },
  ],
};
