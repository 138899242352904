import React, { useEffect } from "react";

import Header from "./Header";
import Footer from "./Footer";

import ScrollToTop from "react-scroll-to-top";
import { lStorage } from "utils/storage";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { loginList } from "assets/data/loginData";
import { toast, ToastContainer } from "react-toastify";

const Paypallayout = ({ children, isSticky, wrapperClass }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const getLogVal = typeof window !== "undefined" && lStorage.get("hccbLogin");

  const onSubmit = async (data) => {
    const pwValidation = loginList.find((value) => value.pw === data.password);
    if (pwValidation !== undefined && pwValidation.pw === data.password) {
      lStorage.set("hccbLogin", "loggedIn");
    } else {
      toast.error("Please enter valid password");
    }
  };

  useEffect(() => {}, []);

  return (
    <div className={wrapperClass}>
      {getLogVal !== undefined &&
      getLogVal !== "" &&
      getLogVal === "loggedIn" ? (
        <>
          <Header isSticky={isSticky} />
          <main className="main-wrapper" id="main-wrapper">
            {children}
          </main>
          <Footer />
          <ScrollToTop smooth className="fbh-scroll-to-top" />
        </>
      ) : (
        <Modal show={true} centered>
          <Modal.Header>HCCB Login</Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="row">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-md-12">
                      <div>
                        <label htmlFor="password" className="form-label">
                          Please enter password to access the HCCB Portal{" "}
                          <i className="hastric-color">*</i>
                        </label>
                        <input
                          type="text"
                          name="password"
                          className="form-control "
                          id="password"
                          placeholder=""
                          {...register("password", {
                            required: "password is required",
                          })}
                        />
                        {errors.password && (
                          <span className="form-error">
                            {errors.password.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="text-right">
                    <button className="btn btn-viewmore save-width">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Modal.Body>{" "}
        </Modal>
      )}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        hideProgressBar
        closeOnClick
        rtl={false}
      />
    </div>
  );
};

export default Paypallayout;
