import React from "react";

// import programexecute from "assets/images/hccb/program-execute.png";

import programethree from "assets/images/paypal/program-execute/3.jpg";

import paypalprogram from "assets/images/paypal/program-execute/5.jpg";

export default function Programexecute() {
  return (
    <div className=" hccb_inner-pages program-execute-section col-md-12 col-lg-12">
      <div className="container">
        <div className="row">
          <div className="col-md-6 order-2">
            <img
              className="program-execute-pic"
              src={programethree}
              alt="vadora ashramam"
            />
          </div>
          <div className="col-md-6 order-1">
            <h3 className="hccb-heading">How was this Program Executed?</h3>
            <p className="hccb-p-text">
              These forests were be created using FBH's proprietary Hearty
              culture High Density (HCHD) plantation method. This involved
              extensive soil reconstitution using Activated Biochar,
              Vermicomposting, Cow manure and cocopeat. The selection of
              species, besides being native and endemic, is such that they
              support one another through an extensive root network. Within 1
              year, the saplings will grow to a height of 10-15 ft, which is
              several times the growth of a regular plantation. And in 2 years'
              time, the forest would be impenetrable with no sunlight reaching
              the soil.
            </p>
          </div>
        </div>
        <div className="row pad60-0">
          <div className="col-md-6 order-sm-1 order-md-2">
            <h3 className="hccb-heading">
              {" "}
              How are Paypal employees part of this initiative?{" "}
            </h3>
            <p className="hccb-p-text">
              Paypal employees are encouraged to volunteer and become
              sustainability stewards whenever any tree plantation drive is
              conducted in their respective locations. The employees also take
              the onus to replenish the trees they have planted, in case of a
              natural stamp out.
            </p>
          </div>
          <div className="col-md-6 order-sm-2 order-lg-1">
            <img
              className="program-execute-pic"
              src={paypalprogram}
              alt="hccb paint"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
