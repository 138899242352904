import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "gatsby";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="video">
          <iframe
            src="https://www.youtube.com/embed/j094cNy53LI"
            title="PayPal Employee Engagement Video"
            description="PayPal Employee Engagement Video"
            alt="A 3-Minute Guided Meditation | Prayer For Heartfulness Prayer"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            width="100%"
            height="410px"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          />
          )
        </div>
      </Modal.Body>
    </Modal>
  );
}

function Kanhavideo() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <span
        onKeyDown={() => setModalShow(true)}
        onClick={() => setModalShow(true)}
        role="button"
        tabIndex="0"
      >
        <Link
          className="viewmap-tag"
          target="_self"
          rel="noreferrer"
          style={{ float: "left" }}
        >
          View in videos
        </Link>
      </span>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default Kanhavideo;
