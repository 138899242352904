import React, { useState, useEffect } from "react";

import { SRLWrapper } from "simple-react-lightbox";

import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024,
    },
    items: 3,
    partialVisibilityGutter: 30,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 30,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464,
    },
    items: 2,
    partialVisibilityGutter: 30,
  },
};

export default function PaypalRecentPlantations(props) {
  const [galleryImages2, setGalleryImages] = useState([]);

  useEffect(() => {
    setGalleryImages(props.images);
  }, []);

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div className="carousel-button-group">
        <div className="arrow-navigations custom-arrow-navigation-event">
          <button className="pr-custom top-hccb" onClick={() => previous()}>
            <i className="icon icon-arrow-left-short"></i>
          </button>
          <button className="pr-rightcustom top-hccb" onClick={() => next()}>
            <i className="icon icon-arrow-right-short"></i>
          </button>
        </div>
      </div>
    );
  };

  return (
    <div
      className="recent-plantation-section bosch-recent-plant hyd-recent-plant"
      style={{ padding: "10px 0px" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-title">
              <h3 className="title-theme-heading" style={{ textAlign: "left" }}>
                {" "}
                Event Photography{" "}
              </h3>
            </div>
            <div className="section pad-photography">
              <div className="carousel-section">
                <SRLWrapper>
                  <Carousel
                    arrows={false}
                    autoPlaySpeed={3000}
                    draggable
                    infinite
                    keyBoardControl
                    minimumTouchDrag={80}
                    partialVisible
                    renderButtonGroupOutside={true}
                    swipeable
                    responsive={responsive}
                    customButtonGroup={<ButtonGroup />}
                  >
                    {galleryImages2.map((item) => {
                      return (
                        <div className="img-wrapper" key={item.id}>
                          <img
                            data-src={item.img}
                            className="lazyload"
                            alt={item.alt}
                          />
                          <div className="overlay">
                            <i className="icon icon-arrows-fullscreen"></i>
                          </div>
                        </div>
                      );
                    })}
                  </Carousel>
                </SRLWrapper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
